/*window.addEventListener("scroll", function (event) {
    var scroll = this.scrollY;
    var app_nav = document.getElementById("app_nav");
    var wrapper = document.getElementById("wrapper");
    if(scroll > 0){
    	document.body.classList.add("scroll");
    	app_nav.classList.add("fixed-top");
    	var style = window.getComputedStyle(app_nav)
    	wrapper.style.paddingTop = style.height;
    }else{
    	document.body.classList.remove("scroll");
    	app_nav.classList.remove("fixed-top");
    	wrapper.style.paddingTop=0;
    }
});*/

window.$ = window.jQuery = require('jquery');
